
/* all → -apple-system, BlinkMacSystemFont, "Segoe UI" */
*                                               { margin: 0; padding: 0; font-size: 14px; font-family: KasperskySans, Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif; vertical-align: top; text-align: left;}
a                                               { text-decoration: none; padding: 0; margin: 0; }
html, body, #root, .App                         { width: 100%; height:100%;}
body                                            { margin: 0 auto; vertical-align: top; background-color: #ffffff; }

#body {border: 16px solid rgba(255,255,255,0.01); max-width: 1140px; margin: 0 auto;}

#popup {z-index: 2;position: fixed;width: 100%;height: 100%;background-color: rgba(68,68,68,0.4);}
#key {z-index: 3; position: fixed; top: 100px; left: calc(50% - 366px); width:700px; padding: 16px; background-color: #FFFFFF;}
#key > div > div {width: 100%;}
#key > div:nth-child(1) {text-align: right;}
#key > div:nth-child(1) > div {background-image: url("./img/close.svg"); background-repeat: no-repeat; width: 16px; height: 16px; display: inline-flex;}
#key > div:nth-child(1) > div:hover {cursor: pointer;}
#key > div:nth-child(2) {text-align: center;}
#key > div:nth-child(2) {font-weight: 700; font-size: 20px; line-height: 30px;}
#key > div:nth-child(2) {text-align: center;}
#key > div:nth-child(3) > div {width: 480px; padding: 16px 16px 40px 16px; margin: 0 auto;}

#key > div:nth-child(3) > div > div:nth-child(1) {display:inline-flex;width: 380px; padding: 11px 12px 10px 11px; border: 1px solid #E5E5E5; text-align: center; justify-content: center; min-height: 19px; font-weight: 500; font-size: 16px; line-height: 18px; }
#key > div:nth-child(3) > div > div:nth-child(2) {display:inline-flex;background-image: url("./img/copy.svg"); background-repeat: no-repeat; background-position: center center; height: 16px; width: 40px; border-top: 1px solid #E5E5E5; border-right: 1px solid #E5E5E5; border-bottom: 1px solid #E5E5E5; font-weight: 500; font-size: 16px; line-height: 18px; padding: 12px;}
#key > div:nth-child(3) > div > div:nth-child(2):hover {cursor: pointer;}

/* ---- */
#head {display: block; text-align: center; padding: 88px 0 0 0;}
#head > div {display: inline-block;text-align: center;}
#head > div:nth-child(1) {background-image: url("./img/logo.svg"); background-position: center center; width: 120px; height: 90px; background-repeat: no-repeat;}
#head > div:nth-child(2) {max-width: 100%; width: 800px;}
#head > div:nth-child(2) > div:nth-child(1) {font-weight: 700; font-size: 36px; line-height: 46px;}
#head > div:nth-child(2) > div:nth-child(2) {font-weight: 400; font-size: 27px; line-height: 46px;}

/* ---- */
#show {padding-top: 68px; text-align: center; display: flex; justify-content: center;}
#show > div {width: 348px; margin: 0 16px 0 16px; box-shadow: 0 8px 24px 0 rgba(29,29,27,0.08);}
#show > div > div:nth-child(1) > div {background-repeat: no-repeat; width: 348px; height: 196px;}
#show > div:nth-child(1) > div:nth-child(1) > div {background-image: url("./img/im1.svg");}
#show > div:nth-child(2) > div:nth-child(1) > div {background-image: url("./img/im2.svg");}
#show > div:nth-child(3) > div:nth-child(1) > div {background-image: url("./img/im3.svg");}
#show > div > div:nth-child(2) {font-weight: 700; font-size: 21px; line-height: 25px; padding: 40px 24px 0 24px;}
#show > div > div:nth-child(3) {font-weight: 500; font-size: 18px; line-height: 22px; padding: 0 24px 40px 24px; color: #666666;}

/* ---- */
#name > div {text-align: center;}
#name > div:nth-child(1) {font-weight: 700; font-size: 40px; line-height: 48px; margin-top: 68px;}
#name > div:nth-child(2) {font-weight: 500; font-size: 21px; line-height: 25px; color: #444444; margin-top: 24px;}
#name > div:nth-child(2) > a {font-weight: 500; font-size: 21px; line-height: 25px; color: #00836F; border-bottom: 1px solid #00836F;}
#name > div:nth-child(2) > a:hover {border-bottom: 1px solid #FFFFFF;}

/* ---- */
#how > div {text-align: center; margin: 0 auto;}
#how > div:nth-child(1) {font-weight: 700; font-size: 40px; line-height: 48px; margin-top: 68px;}
#how > div:nth-child(2) {font-size: 24px; line-height: 28px; text-align: center; color: #00836F; margin-top: 44px; border: 2px solid #00836F; border-radius: 4px; max-width: 305px; padding: 18px;}
#how > div:nth-child(2):hover {background-color: #00836F; color: #FFFFFF; cursor: pointer;}

#steps {}
#steps > div {display: flex; padding-top: 29px;align-items: center;}
#steps > div > div:nth-child(2) {font-weight: 500; font-size: 18px; line-height: 22px; color: #444444; flex: 1;}
#steps > div > div:nth-child(2) > a {font-weight: 500; font-size: 18px; line-height: 22px; color: #00836F;border-bottom: 1px solid #00836F;}
#steps > div > div:nth-child(2) > a:hover {border-bottom: 1px solid #FFFFFF;}
#steps > div:nth-child(1) > div:nth-child(1) {background-image: url("./img/s1.svg"); width: 75px; height: 40px; background-repeat: no-repeat;}
#steps > div:nth-child(2) > div:nth-child(1) {background-image: url("./img/s2.svg"); width: 75px; height: 40px; background-repeat: no-repeat;}
#steps > div:nth-child(3) > div:nth-child(1) {background-image: url("./img/s3.svg"); width: 75px; height: 40px; background-repeat: no-repeat;}

#helps {margin: 100px 0 0 0; height: 100px;}
#help {width: 124px; text-align: right; border: 2px solid #00836F; display: inline-flex; float: right; justify-content: center; align-items: center; border-radius: 4px;}
#help:hover {background-color: #00836F; color: #000000; cursor: pointer; filter: brightness(100%);}
#help:hover > div {filter: invert(0%) hue-rotate(50deg) brightness(0%);}
#help > div:nth-child(1) {background-image: url("./img/support.svg"); background-repeat: no-repeat; background-position: center center; width: 16px; height: 16px; padding: 12px 24px 12px 0;}
#help > div:nth-child(2) {font-weight: 500; font-size: 16px; line-height: 18px; padding: 0 12px 0 0;}

#step1 {display: flex; padding-top: 68px;}
#step1 > div {flex: 1; height: 407px;}
#step1 > div:nth-child(1) {flex:1;}
#step1 > div:nth-child(1) > div:nth-child(1) {background-image: url("./img/st1.svg"); background-repeat: no-repeat; width: 464px; height: 96px; }
#step1 > div:nth-child(1) > div:nth-child(2) {font-weight: 500; font-size: 18px; line-height: 22px; color: #444444; margin-top: 50px;}
#step1 > div:nth-child(1) > div:nth-child(3) {margin-top: 50px; background-image: url("./img/buttons.svg"); background-repeat: no-repeat; background-size: 564px; height: 48px;}
#step1 > div:nth-child(1) > div:nth-child(3) > a:nth-child(1) {width: 240px; height: 48px; display: inline-flex; margin-right: 20px;}
#step1 > div:nth-child(1) > div:nth-child(3) > a:nth-child(2) {width: 240px; height: 48px; display: inline-flex;}
#step1 > div:nth-child(2) > div {flex:1; background-image: url("./img/p1.svg"); background-repeat: no-repeat; background-position: center center; width: 464px; height: 407px; background-position-x: 30px;}

#step2 {display: flex; height: 393px;}
#step2 > div {flex: 1;}
#step2 > div:nth-child(1) > div {background-image: url("./img/p2.svg"); background-repeat: no-repeat; background-position: center center; width: 205px; height: 393px;margin: 0 0 0 200px;}
#step2 > div:nth-child(2) > div:nth-child(1) {margin-top: 50px; background-image: url("./img/st2.svg"); background-repeat: no-repeat; width: 464px; height: 98px; }
#step2 > div:nth-child(2) > div:nth-child(2) {font-weight: 500; font-size: 18px; line-height: 22px; color: #444444;margin-top: 50px;}
#step2 > div:nth-child(2) > div:nth-child(2) > a {font-weight: 500; font-size: 18px; line-height: 22px; color: #00836F;border-bottom: 1px solid #00836F;}
#step2 > div:nth-child(2) > div:nth-child(2) > a:hover {border-bottom: 1px solid #FFFFFF;}

#step3 {display: flex; padding-top: 40px;}
#step3 > div {flex: 1;}
#step3 > div:nth-child(1) {}
#step3 > div:nth-child(1) > div:nth-child(1) {margin-top:50px; background-image: url("./img/st3.svg"); background-repeat: no-repeat; width: 464px; height: 96px; }
#step3 > div:nth-child(1) > div:nth-child(2) {font-weight: 500; font-size: 18px; line-height: 22px; color: #444444;margin-top: 50px;}
#step3 > div:nth-child(1) > div:nth-child(3) {margin-top: 50px; background-image: url("./img/buttons.svg"); background-repeat: no-repeat; background-size: 564px; height: 48px;}
#step3 > div:nth-child(1) > div:nth-child(3) > a:nth-child(1) {width: 240px; height: 48px; display: inline-flex; margin-right: 20px;}
#step3 > div:nth-child(1) > div:nth-child(3) > a:nth-child(2) {width: 240px; height: 48px; display: inline-flex;}
#step3 > div:nth-child(2) > div {height: 395px;}
#step3 > div:nth-child(2) > div {display: inline-flex; padding-right: 40px; height: 395px;}
#step3 > div:nth-child(2) > div:nth-child(1) {flex: 1; background-image: url("./img/p3.svg"); background-repeat: no-repeat; background-position: center center; width: 206px; height: 395px;}
#step3 > div:nth-child(2) > div:nth-child(2) {flex: 1; background-image: url("./img/p4.svg"); background-repeat: no-repeat; background-position: center center; width: 206px; height: 395px;}
#step3 > div:nth-child(1) > div:nth-child(2) > a {font-weight: 500; font-size: 18px; line-height: 22px; color: #00836F;border-bottom: 1px solid #00836F;}
#step3 > div:nth-child(1) > div:nth-child(2) > a:hover {border-bottom: 1px solid #FFFFFF;}

#key_err {text-align: center; margin: 16px 0 16px 0; }

/* loader */
.layerLoader                         {width: 100% !important; border: 0 !important;}
.preLoader                           {  margin: 0 auto; width: 120px; height: 120px; }
.loader                              { border: 16px solid #f3f3f3;border-top: 16px solid #00836F;border-radius: 50%;width: 120px;height: 120px;animation: spin 2s linear infinite; }
@keyframes spin                      { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }

/* mobile */
@media (max-width: 600px) {
    .App {
        zoom: 0.6;
        -ms-zoom: 0.6;
        -webkit-zoom: 0.6;
        -moz-transform-origin: center center;
    }
}
@media (max-width: 1200px) {
    #show {display: block; margin: 0 auto; text-align: center; width: 360px;}
    #show > div {margin: 16px 0 0 0; display: block;}
    #show > div > div:nth-child(1) {display: flex;}
    #show > div > div:nth-child(1) > div {flex: 1; background-position: center center;}
}
@media (max-width: 1060px) {
    #step1 {flex-direction: column; margin: 0 auto; max-width: 505px; }
    #step1 > div {width: 100%; justify-content: center;}
    #step1 > div:nth-child(1) {order: 2; margin-top: 20px; }
    #step1 > div:nth-child(2) {order: 1; padding: 16px;}
    #step1 > div:nth-child(2) > div {background-position-x: -40px;}

    #step2 {flex-direction: column; margin: 0 auto; height: auto; max-width: 505px;}
    #step2 > div:nth-child(1) {order: 1; margin-top: 80px;}
    #step2 > div:nth-child(1) > div {margin: 0 auto;}
    #step2 > div:nth-child(2) {order: 2; margin-top: 80px;}
    #step2 > div:nth-child(2) > div:nth-child(1) {margin: 0 auto;}

    #step3 {flex-direction: column; margin: 0 auto; height: auto; max-width: 505px;}
    #step3 > div:nth-child(1) {order: 2; margin-top: 80px;}
    #step3 > div:nth-child(1) > div:nth-child(1) {margin: 0 auto;}
    #step3 > div:nth-child(2) {order: 1;}
}
@media (max-width: 950px) {
    #head > div {display: block;}
    #head > div:nth-child(1), #head > div:nth-child(2), #head > div:nth-child(2) > div:nth-child(1), #head > div:nth-child(2) > div:nth-child(2) {text-align: center; width: 100%;}
}
@media (max-width: 732px) {
    #key {left: 0; width: calc(100% - 32px);}
}
@media (max-width: 550px) {
    #key > div:nth-child(3) > div {display: block; padding: 16px 0; width: calc(100% - 32px);}
    #key > div:nth-child(3) > div > div:nth-child(1) {width: calc(100% - 32px);}
    #key > div:nth-child(3) > div > div:nth-child(2) {width: calc(100% - 32px);}
}
